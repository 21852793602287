<template>
    <div class="page">
        <banner></banner>
        <div class="tuijian-wrap w-1300">
            <div class="center-text">
                <p></p>
                <span>国家区域</span>
            </div>
            <div class="state-wrap">
                <div class="state" v-for="(item,index) in guojiaList" :key="index">
                    <div class="state-list">
                        <div class="state-item-text">{{item.name}}</div>
                        <div class="state-item" v-for="(item1,index1) in item.cs.slice(0,6)" :key="index1"  @click="goTo('/ziyuan_list',{p_id:item1.p_id,id:item1.id,})">
                            <img :src="item1.banner_url" alt="">
                            <p>{{item1.name}}</p>
                        </div>                      
                    </div>
                    <div class="more"  @click="goTo('/ziyuan_state',{id:item.id})">
                        <p>更多</p>                         
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="kjdspt-bj">
            <img :src="gg[0]?gg[0].pic_url:require('../../assets/images/kjdspt-bj1.png')" alt="">
            <!-- <div class="kjdspt-p">
                <div class="kjdspt-p1">海外资源 ，一站搞定</div>
                <div class="kjdspt-p2">欢迎加入我们，与平台共建共享全球资源库。</div>
            </div> -->
        </div>

        <div class="tuijian-wrap w-1300">
            <div class="pingtai">
                <div class="pingtai-text">
                    <div class="pingtai-p">{{gg[1]?gg[1].title:''}}</div>
                    <div class="pingtai-p1">{{gg[1]?gg[1].content:''}}</div>
                </div>
                <div class="hahah-img"><img :src="gg[1]?gg[1].pic_url:require('../../assets/images/gongjubj.png')" alt=""></div>
                
                <div class="pingtai-list">
                    <div class="pingtai-item">
                        <img :src="gg[2]?gg[2].pic_url:require('../../assets/images/kjdspt1.png')" alt="">
                        <div class="pingtai-item-p">{{gg[2]?gg[2].title:''}}</div>
                        <div class="pingtai-item-p1">{{gg[2]?gg[2].content:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[3]?gg[3].pic_url:require('../../assets/images/kjdspt1.png')" alt="">
                        <div class="pingtai-item-p">{{gg[3]?gg[3].title:''}}</div>
                        <div class="pingtai-item-p1">{{gg[3]?gg[3].content:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[4]?gg[4].pic_url:require('../../assets/images/kjdspt1.png')" alt="">
                        <div class="pingtai-item-p">{{gg[4]?gg[4].title:''}}</div>
                        <div class="pingtai-item-p1">{{gg[4]?gg[4].content:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[5]?gg[5].pic_url:require('../../assets/images/kjdspt1.png')" alt="">
                        <div class="pingtai-item-p">{{gg[5]?gg[5].title:''}}</div>
                        <div class="pingtai-item-p1">{{gg[5]?gg[5].content:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[6]?gg[6].pic_url:require('../../assets/images/kjdspt1.png')" alt="">
                        <div class="pingtai-item-p">{{gg[6]?gg[6].title:''}}</div>
                        <div class="pingtai-item-p1">{{gg[6]?gg[6].content:''}}</div>
                    </div>
                </div>
            </div>
            
        </div>





    </div>
</template>

<script>
    import banner from '../../components/banner.vue';
    import {getcountrys,getplateAds} from '../../api/index';
    export default {
        data() {
            return {
                tuijianLoading:false,
                guojiaList:[],
                gg:[]
                
            }
        },
        components:{
            banner
        },
        created() {
            this.getcountrys()
            this.getplateAds()
            
        },
        methods: {
            // 广告
            getplateAds(){
                getplateAds({
                    plate_id:24,
                    seat:0
                }).then((res)=>{
                    // console.log(res)
                    if(res.code==200){
                        this.gg=res.data
                    }
                })
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            //获取国家列表
            getcountrys(){
                getcountrys().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.guojiaList=res.data
                    }
                })
            }
            
        },
        
    }
</script>

<style lang="less" scoped>
    .page{overflow: hidden;}
    .tuijian-wrap{
        margin: auto;
        .tuijian-list{
            margin-top: 40px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .tuijian-item{
                width: 18%;
                margin-right: 2.5%;
                padding: 0 10px;
                border: 1px solid #E8E8E8;
                background: #FFFFFF;
                box-sizing: border-box;
                margin-bottom: 35px;
                .tuijian-top{
                    padding: 20px 0 15px 0;
                    display: flex;
                    border-bottom: 1px solid #E8E8E8;
                    .tuijian-img{
                        width: 55px;flex-shrink: 0;
                        img{width: 100%;}
                    }
                    .tuijian-text{
                        margin-left: 10px;
                        .tuijian-p{color: #000000;font-size: 16px;font-weight: bold;margin-bottom: 6px;}
                        .tuijian-p1{color: #666666;font-size: 14px;font-weight: 400;}
                    }
                }
                .tuijian-bottom{
                    padding: 15px 0;
                    display: flex;justify-content: space-between;
                    .tuijian-bt{width: 45%;height: 30px;line-height: 30px;text-align: center;background: #F4F6F7;
                        color: #666666;font-size: 14px;font-weight: 400;border-radius: 15px;cursor: pointer;
                        &:hover{background: #0071DB;color: #fff;}
                    }
                }
                &:hover{
                    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
                }
                &:nth-child(5n+0){margin-right: 0;}

            }
        }
        .pingtai{
            overflow: hidden;
            .pingtai-text{
                margin: 75px auto ;text-align:center;
                .pingtai-p{color: #333333;font-size: 35px;}
                .pingtai-p1{color:#666666;font-size: 16px;font-weight: 300;margin-top: 15px;}
            }
            .hahah-img{
                width: 90%;margin: auto;
                img{width: 100%;}
            }
            .pingtai-list{
                display: flex;justify-content: space-between;margin:60px 0 100px 0;
                .pingtai-item{
                    width: 17%;border: 1px solid #F3F3F3;padding: 30px;box-sizing: border-box;text-align: center;background: #F7FAFF;
                    img{width: 67px;height: 67px;margin: auto;}
                    .pingtai-item-p{color: #000000;font-size: 18px;margin: 20px 0;}
                    .pingtai-item-p1{color: #666666;font-size: 14px;}
                }
            }
        }

        .state-wrap{
            margin-top: 10px;overflow: hidden;padding:0 20px;
            .state{
                 margin:35px 0;display: flex;align-items: center;justify-content: space-between;
                .state-list{
                    display: flex;align-items: center;flex: 1;
                    .state-item-text{color: #000;font-size: 16px;font-weight: bold;width: 130px;}
                    .state-item{
                        display: flex;align-items: center;font-size:16px;color:#000;cursor: pointer;
                        img{width: 28px;margin-right: 10px;}
                        p{width: 120px;}
                        &:hover{color: #FF7048;text-decoration:underline;}
                    }
                }
                .more{
                    display: flex;align-items: center;color: #FF7048;flex-shrink: 0;cursor: pointer;
                    font-size: 16px;
                    p{margin: 0 5px 1px 0;}
                }
            }

        }
        
    }

    .kjdspt-bj{
        width: 100%;height: 387px;margin-top:45px;position: relative;
        img{width: 100%;height: 100%;display: flex;}
        .kjdspt-p{
            width: 500px;position: absolute;top:50%;left:50%;transform: translate(-20%,-50%);
            .kjdspt-p1{color: #333333;font-size: 35px;margin-bottom: 20px;}
            .kjdspt-p2{color: #000000;font-size: 18px;font-weight: 300;}
        }
    }
    .kuajing-bj{
        width: 100%;position: relative;
        img{width: 100%;height: auto;}
        .kuajing-p{
            width: 100%;position: absolute;top:100px;text-align:center;
            .kuajing-p1{color: #333333;font-size: 35px;margin-bottom: 10px;}
            .kuajing-p2{color: #000000;font-size: 18px;font-weight: 300;}
            .kuajing-bt{
                width: 141px;height: 36px;background: #0071DB;
                border-radius: 15px;line-height: 36px;text-align: center;
                color: #fff;font-size: 17px;font-weight: 300;
                margin: 30px auto;cursor: pointer;
            }
        }
    }

</style>